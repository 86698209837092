<template>
    <div
        v-loading="$wait.is('loading')"
        class="flex flex-1 flex-col self-start client-notes-comments"
        element-loading-spinner="el-custom-spinner"
    >
        <ModalAddComment
            v-if="modalAddCommentVisible"
            :modalAddComment="modalAddComment"
            :clientId="clientId"
            @refresh="getAllComments"
            @close="modalAddComment = false"
            @closed="modalAddCommentVisible = false"
        />

        <div class="card">
            <header class="flex items-center justify-between h-10 border-b border-solid border-gray-200 pb-4 mb-5">
                <h1>{{ $t('clients.notes_comments') }}</h1>
                <el-button :loading="$wait.is('loading')" type="primary" @click="showAddCommentModal">
                    {{ $t('clients.add_comment') }}
                </el-button>
            </header>

            <div class="grid grid-cols-2 gap-8 px-8">
                <div>
                    <div class="flex justify-between items-end mb-2">
                        <p class="text-sm">
                            <fa-icon :icon="['fas', 'users']" fixed-width class="text-lg text-gray-600 mr-1" />
                            {{ $t('clients.public_note') }}
                        </p>
                        <el-button size="mini" type="warning" @click="update('public')">
                            {{ $t('common.update') }}
                        </el-button>
                    </div>
                    <quill-editor
                        ref="myQuillEditor"
                        v-model="note_public"
                        :options="editorOption"
                    />
                    <p class="text-xs text-orange-600 leading-5 mt-2">
                        {{ $t('clients.this_note_is_visible_to_everyone_including_employees_and_it_will_be_attached_to_every_calendar_event') }}
                    </p>
                </div>
                <div>
                    <div class="flex justify-between items-end mb-2">
                        <p class="text-sm">
                            <fa-icon :icon="['fad', 'user-secret']" fixed-width class="text-lg text-gray-600" />
                            {{ $t('clients.private_note') }}
                        </p>
                        <el-button size="mini" type="success" @click="update('private')">
                            {{ $t('common.update') }}
                        </el-button>
                    </div>
                    <quill-editor
                        ref="myQuillEditor"
                        v-model="note_private"
                        :options="editorOption"
                    />
                    <p class="text-xs text-gray-600 leading-5 mt-2">
                        {{ $t('clients.this_note_is_private_and_its_visible_only_for_admin_user_and_managers') }}
                    </p>
                </div>
            </div>
        </div>
        <div v-for="comment in commentsData" :key="comment.id">
            <p class="ml-2 mb-1 mt-6 text-xs text-gray-500">
                {{ comment.created_at }}
            </p>
            <div class="card">
                <p class="mb-4 text-sm text-gray-600">
                    {{ comment.author }} {{ $t('employee_details.wrote') }}:
                </p>
                <el-button
                    class="absolute top-0 right-0 w-10 h-8 p-0 rounded flex justify-center items-center"
                    type="danger"
                    @click="handleDelete(comment.id)"
                >
                    <fa-icon :icon="['fas', 'trash']" />
                </el-button>
                <p>
                    {{ comment.content }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';

export default {
    components: {
        quillEditor,
        ModalAddComment: () => import(/* webpackChunkName: "Client/NotesCommentsModalAdd" */ './NotesCommentsModalAdd'),
    },

    props: {
        clientId: {
            type:    Number,
            default: null,
        },
    },

    data() {
        return {
            note_public:            null,
            note_private:           null,
            clientUuid:             this.$route.params.uuid,
            commentsData:           [],
            modalAddComment:        false,
            modalAddCommentVisible: false,
            editorOption:           {
                placeholder: ' ',
                debug:       false,
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline'],
                    ],
                },
            },
        };
    },

    created() {
        this.getAll();
        this.getAllComments();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${this.clientUuid}`);
            this.note_public = data.note_public;
            this.note_private = data.note_private;
            this.$wait.end('loading');
        },

        async update(type) {
            this.$wait.start('loading');

            const newData = {
                public: {
                    note_public: this.note_public,
                },
                private: {
                    note_private: this.note_private,
                },
            };

            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${this.clientUuid}/update_notes`, newData[type]);
            this.$notify.success({ title: 'Success' });
            this.$wait.end('loading');
        },

        async getAllComments() {
            this.$wait.start('loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${this.clientId}/comments`);
            this.commentsData = data;

            this.$wait.end('loading');
        },

        async showAddCommentModal() {
            this.modalAddCommentVisible = true;
            await new Promise(resolve => setTimeout(resolve, 50));
            this.modalAddComment = true;
        },

        handleDelete(id) {
            this.$confirm(this.$t('common.are_you_sure'), this.$t('common.warning'), {
                confirmButtonText: this.$t('common.yes'),
                cancelButtonText:  this.$t('common.cancel'),
                type:              'warning',
                beforeClose:       async (action, instance, done) => {
                    if (action === 'confirm') {
                        // @Filip
                        instance.confirmButtonLoading = true;
                        instance.closeOnClickModal = false;

                        try {
                            await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${this.clientId}/comments/${id}`);
                            this.$notify.success({ title: 'Success' });
                            this.getAllComments();
                        } catch {
                            return;
                        }

                        done();
                        // @Filip
                        instance.confirmButtonLoading = false;
                        instance.closeOnClickModal = true;
                    } else {
                        done();
                    }
                },
            });
        },
    },
};
</script>
<style>
.client-notes-comments .ql-editor {
    min-height: 200px;
}
.quill-editor .ql-toolbar{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-color: #ddd;
}
.client-notes-comments .quill-editor .ql-container{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: #ddd;
}
</style>
